import { ControllerParams } from '@wix/yoshi-flow-editor';

export type RequiredControllerParams = Pick<
  ControllerParams,
  'controllerConfig' | 'flowAPI'
>;

export type FlowAPI = ControllerParams['flowAPI'];

export type HttpClient = FlowAPI['httpClient'];

export type WixExperiments = FlowAPI['experiments'];

export type ControllerConfig = ControllerParams['controllerConfig'];

export type WixCodeApi = ControllerParams['controllerConfig']['wixCodeApi'];

export type CurrentUser = WixCodeApi['user']['currentUser'];

export type ViewMode = WixCodeApi['window']['viewMode'];

interface RouterMemberData {
  memberContactId: string;
}

interface RouterPageData {
  memberData?: RouterMemberData;
}

interface ViewedUser {
  id: string;
}

export interface RouterPublicData {
  viewedUser?: ViewedUser;
}

export interface RouterData {
  memberData?: RouterMemberData;
  pageData?: RouterPageData;
  publicData?: RouterPublicData;
}

export type AppParams = ControllerConfig['appParams'] & {
  routerReturnedData?: RouterData;
};

export enum WarmupDataKey {
  InitialData = 'initialData',
}
